import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { createTicket } from '../../functional/api';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    inputValidationNonEmpty,
    inputValidationSingleChoice,
    inputValidationYesSelected,
} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        clientId: -1,
        clients: [],

        headquartersId: -1,

        type: -1,
    
        warrantyFirm: -1,

        differentBeneficiary: 0,

        equipments: [ { title: '', model: '', series: '', problem: '' } ],
        equipments_chosen: [],
        equipments_all: [],

        beneficiary: '',
        title: '',
        description: '',
        phone: '',

        info: {},

        formComplete: false,

        operatorId: -1,
        
        fixedClient: -1,
        
        priority: 'normal',
    
        addEquipmentModal: false,
    
        chooseOperatorModalOpen: false,
        
        operatorSch: null,
        timeSch: null,
        dateSch: null,
    };

    render () {
        return (
            <BaseModal
                title={'Adauga solicitare'}
                stableHeight
                stableWidth
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>
                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.title)}
                            advancedPadding
                            icon={'ft-hash'}
                            value={this.state.title}
                            style={{ marginBottom: 12 }}
                            label={'Denumirea solicitarii'}
                            onChange={(newValue) => {
                                this.setState({
                                    title: newValue,
                                });
                            }}/>

                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.fixedClient > 0 ? this.state.fixedClient : this.state.clientId)}

                            blocked={this.state.fixedClient > 0}
                            icon={'ft-briefcase'}
                            label={'Clientul'}
                            predefinedModal={'client'}
                            value={this.state.fixedClient > 0 ? this.state.fixedClient : this.state.clientId}
                            style={{ marginBottom: 12 }}
                            onSelect={(value) => {
                                this.setState({
                                    clientId: value,
                                });
                            }}/>
                        
                        <TextFieldInput
                            required
                            textarea
                            validated={inputValidationNonEmpty(this.state.description)}
                            advancedPadding
                            icon={'ft-align-justify'}
                            value={this.state.description}
                            style={{ marginBottom: 12 }}
                            label={'Detaliile generale ale solicitarii'}
                            onChange={(newValue) => {
                                this.setState({
                                    description: newValue,
                                });
                            }}/>
    
                    </Form>
                    
                </div>

            </BaseModal>
        );
    }
    
    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {

        const request = {
            clientId: this.state.clientId,
            title: this.state.title,
            description: this.state.description,
        };
        
        this.props.openLoading();

        createTicket(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Solicitarea a fost inregistrata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
    
    };

    clear = () => {
        this.setState({
            clientId: -1,
            clients: [],

            headquartersId: -1,
            equipments_chosen: [],

            type: -1,

            differentBeneficiary: 0,

            equipments: [ { title: '', model: '', series: '', problem: '' } ],

            beneficiary: '',
            title: '',
            description: '',
            phone: '',
            
            priority: 'normal',
            fixedClient: -1,

            info: {},
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);