import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllClientGroups, getAllClients } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterClientFields, kFilterClientFieldsDefault } from '../functional/constants';
import AddClientModal from '../components/modal/AddClientModal';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { connect } from 'react-redux';
import { toClientGroupPage, toClientPage } from '../functional/navigation';
import { applicationAddSnack } from '../store/application-store';
import PagedPage from '../layout/page/PagedPage';
import { compareGeneratorString, prettyDateAndTime } from '../functional/min-support';
import FileUploadModal from '../components/modal/FileUploadModal';

const clientFragments = () => {
    return [
        {
            id: 'clients',
            title: 'CL',
            icon: 'ft-user',
            color: '#091762',
            label: '',
            type: 'list',
            listFilter: (item) => { return item.cont === 'client'; },
        },
        {
            id: 'groups',
            title: 'GR',
            icon: 'ft-users',
            color: '#1d5d09',
            label: '',
            type: 'list',
            listFilter: (item) => { return item.cont === 'group'; },
        },
    ];
};

class ClientsPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        itemsGroups: null,
        modalOpen: false,

        uploadOpen: false,

        pendingUsers: [],
    };

    state = ClientsPage.defaultState;

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(ClientsPage.defaultState, this.update);
    };

    update = () => {
        getAllClients((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no clients');
            }
        });

        getAllClientGroups((successful, data) => {
            if (successful) {
                this.setState({
                    itemsGroups: data,
                });
            } else {
                console.error('no clients');
            }
        });
    };

    render () {
        const entireList = [];
        if (this.state.items) {
            const mapped = this.state.items.map((client) => {
                return { ...client, cont: 'client' };
            });
            entireList.push(...mapped);
        }

        if (this.state.itemsGroups) {
            const mapped = this.state.itemsGroups.map((group) => {
                return { ...group, cont: 'group' };
            });
            entireList.push(...mapped);
        }

        const actions = [];

        if (this.props.user.level != 'affiliate') {
            actions.push(
                {
                    title: 'Client nou',
                    icon: { title: 'ft-plus' },
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    },
                }
            );

            // actions.push(
            //     {
            //         title: 'Baza de date clienti',
            //         icon: { title: 'ft-upload-cloud' },
            //         onPress: () => {
            //             this.setState({
            //                 uploadOpen: true,
            //             });
            //         },
            //     }
            // );
        }

        /*
         * Verificare client bifa -> de implementat
         * Newsletter curatare baza de date
         */

        return (
            <PagedPage
                grid
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null || this.state.itemsGroups === null}
                actions={actions}
                allListItems={entireList}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}

                fragments={[
                    {
                        id: 'status',
                        title: 'Parteneri',
                        icon: 'ft-code',
                        noDisplay: true,
                        children: clientFragments(),
                    },
                ]}
                defaultFragment={clientFragments()[ 0 ]}

                paddingRight

                tableFields={{
                    'title': {
                        title: 'Denumire',
                        transform: (item) => { return item.title; },
                        action: (item) => { toClientPage(item._id); },
                        compare: compareGeneratorString('title'),
                        icon: 'ft-file-text',
                    },
                    'cid': {
                        title: 'Cod Identificare Fiscala',
                        transform: (item) => { return item.cid; },
                        action: (item) => { toClientPage(item._id); },
                        compare: compareGeneratorString('cid'),
                        icon: 'ft-hash',
                    },
                    'tri': {
                        title: 'Reg. Com.',
                        transform: (item) => { return item.tri; },
                        action: (item) => { toClientPage(item._id); },
                        compare: compareGeneratorString('tri'),
                        icon: 'ft-code',
                    },
                    'address': {
                        title: 'Sediu',
                        transform: (item) => { return item.address; },
                        compare: compareGeneratorString('address'),
                        action: (item) => { toClientPage(item._id); },
                        icon: 'ft-map-pin',
                    },
                }}
                defaultSort={'created'}

                defaultFields={kFilterClientFieldsDefault}
                allFields={kFilterClientFields}>

                <AddClientModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

                <FileUploadModal
                    monthNumber={this.state.selectedMonthNumber}
                    onDone={() => {
                        this.setState({ uploadOpen: false });
                        setTimeout(() => {
                            this.update();
                        }, 1000);
                    }}
                    onClose={() => {
                        this.setState({ uploadOpen: false });
                        setTimeout(() => {
                            this.update();
                        }, 1000);
                    }}
                    open={this.state.uploadOpen}/>


            </PagedPage>
        );
    }

    onItemClick = (item) => {
        if (item.cont === 'client') {
            toClientPage(item._id);
        } else {
            toClientGroupPage(item._id);
        }

    };
}

const ListItem = (props) => {
    if (props.item.cont === 'client') {
        return (
            <div className={'short-list-item'} style={{ height: 'fit-content' }} onClick={() => {
                props.onPress(props.item);
            }}>

                <div className={'short-list-item-container'}>

                    <div className={'short-list-item-container-inner'}>
                        <TextFieldDisplay
                            noHover
                            noBorders
                            oneLine
                            icon={props.item.verified ? 'ft-check-circle' : 'ft-alert-triangle'}
                            value={props.item.title}
                            style={{ marginBottom: 4, paddingRight: 10 }}/>

                        <TextFieldDisplay
                            noHover
                            noBorders
                            oneLine
                            value={props.item.cid}
                            icon={'ft-info'}
                            style={{ marginBottom: 4, marginLeft: 10 }}/>

                        <TextFieldDisplay
                            noHover
                            noBorders
                            oneLine
                            value={props.item.tri}
                            icon={'blank'}
                            style={{ marginBottom: 4 }}/>

                        <TextFieldDisplay
                            noHover
                            noBorders
                            oneLine
                            value={props.item.address}
                            icon={'ft-map-pin'}
                            style={{ marginBottom: 4 }}/>
                    </div>

                </div>

            </div>
        );
    } else {
        return (
            <div className={'short-list-item'} style={{ height: 'fit-content' }} onClick={() => {
                props.onPress(props.item);
            }}>

                <div className={'short-list-item-container'}>

                    <div className={'short-list-item-container-inner'}>
                        <TextFieldDisplay
                            noHover
                            noBorders
                            oneLine
                            value={props.item.title}
                            style={{ marginBottom: 4, paddingRight: 10 }}/>
                    </div>

                </div>

            </div>
        );
    }
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientsPage));
