import {
    routeAffiliateDashboard, routeAffiliatePage, routeClient,
    routeClients,
    routeDashboard,
    routeDownload,
    routeMail,
    routeReports,
    routeStock,
    routeSystem,
    routeTickets,
    routeUsers,
} from './routes';

const sidebarRoutes = {};

sidebarRoutes.god = [
    routeDashboard,
    // routeTickets,
    routeMail,
    routeClients,
    // routeStock,
    // routeUsers,
    // routeDownload,
    // routeSystem,
    // {
    //     title: 'Rapoarte',
    //     icon: 'ft-bar-chart',
    //     children: [ routeReports ],
    // },
    // routeAffiliatePage,
];

sidebarRoutes.admin = [
    routeDashboard,
    // routeTickets,
    routeMail,
    routeClients,
    // routeStock,
    // routeUsers,
    // routeDownload,
    // routeSystem,
    // {
    //     title: 'Rapoarte',
    //     icon: 'ft-bar-chart',
    //     children: [ routeReports ],
    // },
    // routeAffiliatePage,

];

sidebarRoutes.operator = [
    routeDashboard,
    // routeTickets,
    routeMail,
    routeClients,
    // routeStock,
    // routeDownload,
    // routeSystem,
];

sidebarRoutes.affiliate = [
    routeAffiliateDashboard,
    routeClients,
];
sidebarRoutes.affiliate_admin = [
    routeDashboard,
    // routeTickets,
    routeMail,
    routeClients,
    // routeStock,
    // routeUsers,
    // routeDownload,
    // {
    //     title: 'Rapoarte',
    //     icon: 'ft-bar-chart',
    //     children: [ routeReports ],
    // },
    routeAffiliatePage,
];

sidebarRoutes.outside = [ routeDashboard ];

export default sidebarRoutes;
