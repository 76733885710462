import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import InternalToolbar from '../components/general/InternalToolbar';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import SimpleButton from '../components/input/SimpleButton';
import { applicationAddSnack, applicationSetApplication } from '../store/application-store';
import { connect } from 'react-redux';
import { kToastActionError, kToastActionNormal } from '../components/general/Toast';
import { generateAllTickets, invalidateTestEmails } from '../functional/api';
import FileUploadModal from '../components/modal/FileUploadModal';

class SystemPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        search: '',
        mailUploadModalOpen: false,
    };

    state = SystemPage.defaultState;

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
        });
    };

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(SystemPage.defaultState, this.update);
    };

    update = () => {

    };

    render () {
        return (
            <React.Fragment>

                <InternalToolbar
                    refresh
                    onRefesh={this.generalUpdate}
                    search
                    onSearchChange={this.searchChange}
                    searchValue={this.state.search}/>

                <div className={'scrollable-page ps-4 pb-4'}>

                    <Row className="w-100">
                        <Col className="col-8 stretched_card pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card_title">OlaCRM actiuni sistem</h4>

                                    <p>Alte pagini</p>
                                    <SimpleButton icon={'ft-credit-card'} onPress={() => {
                                        this.props.history.push('/affiliate');
                                    }} value={'Vanzari afiliati'} />
                                    <SimpleButton icon={'ft-layers'} onPress={() => {
                                        this.props.history.push('/stock');
                                    }} value={'Produse'} />
                                    <SimpleButton icon={'ft-bar-chart'} onPress={() => {
                                        this.props.history.push('/reports');
                                    }} value={'Rapoarte'} />

                                    <p style={{ marginTop: 18 }}>Optiuni solicitari</p>
                                    <SimpleButton icon={'ft-check-square'} onPress={() => {
                                        // generateAllTickets((successsful, response) => {
                                        //     console.log('Generate:', successsful, response);
                                        // });
                                        this.props.toast('Operatiune realizata cu succes.', kToastActionNormal);
                                    }} value={'Genereaza solicitari de test'} />

                                    <p style={{ marginTop: 18 }}>Optiuni emailuri</p>
                                    <SimpleButton icon={'ft-trash-2'} onPress={() => {
                                        invalidateTestEmails((successful) => {
                                            // this.generalUpdate();
                                            this.props.toast('Operatiune realizata cu succes.', kToastActionNormal);
                                        });
                                    }} value={'Sterge emailuri de test'} />
                                    <SimpleButton icon={'ft-upload-cloud'} onPress={() => {
                                        this.setState({
                                            mailUploadModalOpen: true,
                                        });
                                    }} value={'Incarca baza de date emailuri'} />

                                    <p style={{ marginTop: 18 }}>Optiuni clienti</p>
                                    <SimpleButton icon={'ft-upload-cloud'} onPress={() => {
                                        this.setState({
                                            mailUploadModalOpen: true,
                                        });
                                    }} value={'Incarca baza de date clienti'} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <FileUploadModal
                    onDone={() => {
                        this.setState({ mailUploadModalOpen: false });
                        setTimeout(() => {
                            // this.update();
                            this.props.toast('Operatiune realizata cu succes.', kToastActionNormal);
                        }, 1000);
                    }}
                    onClose={() => {
                        this.setState({ mailUploadModalOpen: false });
                        setTimeout(() => {
                            // this.update();
                            // this.props.toast('Operatiune realizata cu succes.', kToastActionNormal);
                        }, 1000);
                    }}
                    open={this.state.mailUploadModalOpen}/>

            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(SystemPage));
