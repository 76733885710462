/**
 * @label Stable Imports
 */

import React from 'react';

/**
 * @label Component Imports
 */

import Header from './Header';
import Sidebar from './Sidebar';
import Modals from '../components/modal/Modals';
import Toast from '../components/general/Toast';
import Footer from './Footer';

/**
 * Layout for protected routes.
 * @returns {JSX.Element}
 */

const ProtectedLayout = (props) => {

    /**
     * @label Render
     */

    const pathname = window.location.pathname.replace('/', '');

    return (
        <React.Fragment>
            <div className="page-container sidebar_collapsed">

                <Sidebar />
                <Modals />
                <Toast />

                <div className={pathname === 'blank' ? 'is_blank_page main-content' : 'main-content'} id="main_content_sec">
                    <Header />
                    <div className="main-content-inner">
                        {props.children}
                    </div>
                </div>

                <Footer/>

            </div>
        </React.Fragment>
    );
};

export default ProtectedLayout;
