/**
 * @label Stable Imports
 */

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

/**
 * @label Unstable Imports
 */

import { Scrollbars } from 'react-custom-scrollbars';

/**
 * @label Functionality Imports
 */

import { logicLogout } from '../functional/logic';
import { toUserPage } from '../functional/navigation';
import { getAllHistory, getApplicationHistory, getApplicationMessages, sendApplicationMessageRequest, update } from '../functional/api';
import { parsePhraseSpecialFormatted, prettyDateAndTime, prettyDuration, smallIcon } from '../functional/min-support';
import {
    applicationAddSnack,
    applicationApplicationSelector,
    applicationItemPageTitleSelector,
    applicationLogin, applicationOpenUpdateModal,
    applicationSetApplication,
    applicationToggleDrawerOpen,
    applicationUserSelector,
} from '../store/application-store';
import routes from '../functional/routes';

/**
 * @label Asset Imports
 */

import maleImage from '../assets/images/global/user-placeholder-male.png';
import femaleImage from '../assets/images/global/user-placeholder-female.png';
import { kToastActionError, kToastActionNormal } from '../components/general/Toast';
import SimpleButton from '../components/input/SimpleButton';
import SimpleIcon from '../components/input/SimpleIcon';

/**
 * Top navigation bar of the application.
 * @returns {JSX.Element}
 */

const Header = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * @label Hooks : End
     */


    /**
     * @label State : Start
     */

    const [ userMenuOpen, setUserMenuOpen ] = useState(false);
    const [ notificationMenuOpen, setNotificationMenuOpen ] = useState(false);
    const [ messagesMenuOpen, setMessagesMenuOpen ] = useState(false);

    const [ notifications, setNotifications ] = useState([]);
    const [ messages, setMessages ] = useState([]);

    const [ message, setMessage ] = useState('');

    const [ historyItems, setHistoryItems ] = useState([]);

    /**
     * @label State : End
     */


    /**
     * @label Store : Start
     */

    const user = useSelector(applicationUserSelector);
    const itemPageTitle = useSelector(applicationItemPageTitleSelector);
    const application = useSelector(applicationApplicationSelector);

    /**
     * @label Store : End
     */


    /**
     * @label Functionality : Start
     */

    const updateNotifications = () => {
        getAllHistory((successful, result) => {
            if (successful) {
                setNotifications(result);
            }
        });
    };

    const updateMessages = () => {
        getApplicationMessages((successful, result) => {
            if (successful) {
                setMessages(result);
            }
        });
    };

    const sendApplicationMessage = () => {
        if (message && message.length > 0) {
            sendApplicationMessageRequest(message, (successful) => {
                updateMessages();
            });
            setMessage('');
        }
    };

    const searchInRoutes = (received, level) => {
        const found = routes[ level ].filter((route) => { return route.id === received; });
        if (found.length > 0) {
            return found[ 0 ];
        }
        return null;
    };

    const contextualPage = (lastRoute, position) => {
        if (position === 1) {
            return itemPageTitle;
        }
        return 'Necunoscut';
    };

    const toggleSidebar = () => {
        const pageClass = document.querySelector('.page-container');
        if (pageClass) {
            pageClass.classList.toggle('sidebar_collapsed');
        }
        dispatch(applicationToggleDrawerOpen());
    };

    const onRefesh = () => {
        window.location.reload(true);
    };

    const toggleFullscreen = () => {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };

    const toggleUserMenuOpen = () => { setUserMenuOpen(!userMenuOpen); };
    const toggleNotificationsMenuOpen = () => { setNotificationMenuOpen(!notificationMenuOpen); };
    const toggleMessagesMenuOpen = () => { setMessagesMenuOpen(!messagesMenuOpen); };

    const updateHistory = () => {
        // getApplicationHistory((successful, data) => {
        //     if (successful) {
        //         // console.log('history app', data);
        //         setHistoryItems(data);
        //     }
        // });
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Effects : Start
     */

    useEffect(() => {
        // setTimeout(() => {
        //     toggleSidebar();
        // }, 10);

        const notificationsInterval = setInterval(() => {
            updateNotifications();
        }, 4000);
        updateNotifications();

        const historyItemsUpdateInterval = setInterval(() => {
            updateHistory();
        }, 4000);
        updateHistory();

        let messagesInterval = null;
        if (user.level !== 'affiliate') {
            messagesInterval = setInterval(() => {
                updateMessages();
            }, 1000);
            updateMessages();
        }

        return () => {
            if (notificationsInterval) clearInterval(notificationsInterval);
            if (historyItemsUpdateInterval) clearInterval(historyItemsUpdateInterval);
            if (messagesInterval) clearInterval(messagesInterval);
        };
    }, []);

    /**
     * @label Effects : End
     */


    /**
     * @label Render
     */

    const currentPathname = history.location.pathname;
    const routesLocal = currentPathname.split('/').filter((token) => { return token.length !== 0; });
    let combinedRoute = '';
    let lastRoute = null;
    const breadcrumbs = routesLocal.map((route, position) => {
        combinedRoute += `/${ route }`;
        const foundRoute = searchInRoutes(route, user.level);
        const title = foundRoute ? foundRoute.title : currentPathname === '/dashboard' ? 'Centrul principal' : contextualPage(lastRoute, position);
        lastRoute = foundRoute;
        return {
            title,
            path: combinedRoute,
        };
    });

    return (
        <>
            <div className="header-area">
                <div className="d-flex flex-row align-items-center">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <div className="nav-btn pull-left" onClick={toggleSidebar}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <div className="rt_subheader_main">
                            <div className="rt_breadcrumb">
                                <Link to="/dashboard" className="rt_breadcrumb_home_icon"><span className={'breadcrumb_link'} style={{ paddingRight: 4 }}>{application.title}</span><i className="feather ft-home"/></Link>
                                {
                                    breadcrumbs.map((breadcrumb) => {
                                        return (
                                            <>
                                                <span className="rt_breadcrumb_separator"><i className="feather ft-chevrons-right"/></span>
                                                <Link to={breadcrumb.path} className="breadcrumb_link pl-4">{breadcrumb.title}</Link>
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <ul className="notification-area">
                        {false && <li onClick={onRefesh}><SimpleButton value={'Reactualizeaza'} icon={'ft-refresh-cw'}/></li>}
                        {false && <li id="full-view" className="d_none_sm" onClick={toggleFullscreen}><i className="feather ft-maximize"></i></li>}

                        {
                            user.level !== 'affiliate' &&
                            <Dropdown isOpen={messagesMenuOpen} toggle={toggleMessagesMenuOpen} tag="li">
                                <DropdownToggle tag="i" className="fa fa-envelope-o">
                                    { false && <span></span> }
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu notify-box nt-enveloper-box">
                                    <span className="notify-title">Mesajele {application.title}</span>
                                    <Scrollbars style={{ height: '350px' }}>
                                        <div className="nofity-list">

                                            {
                                                messages.map((currentMessage, index) => {
                                                    return (
                                                        <DropdownItem key={currentMessage.id} tag="a" htef="#" className="notify-item">
                                                            <div className="notify-thumb">
                                                                <img src={currentMessage.sender.gender === 'male' ? maleImage : femaleImage} alt="image1" />
                                                            </div>
                                                            <div className="notify-text">
                                                                <h4>{currentMessage.sender.fullName}</h4>
                                                                <div>
                                                                    <p style={{ maxWidth: '100%', whiteSpace: 'break-spaces' }} className="msg">{currentMessage.body}</p>
                                                                </div>
                                                                <span>{`Acum ${ prettyDuration(moment.duration(moment().diff(currentMessage.created))) }`}</span>
                                                            </div>
                                                        </DropdownItem>
                                                    );
                                                })
                                            }
                                        </div>

                                    </Scrollbars>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 4 }}>
                                        <textarea
                                            className={'text-field-input-value'}
                                            placeholder={'Trimite un mesaj'}
                                            value={message}
                                            onChange={(event) => {
                                                setMessage(event.target.value);
                                            }}
                                            rows={2}/>
                                        <div onClick={sendApplicationMessage} className={'hoover-bg-color-primary'} style={{ cursor: 'pointer', width: 40, height: 40, borderRadius: 100 }}>
                                            {
                                                smallIcon('ft-send')
                                            }
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        }

                        {
                            false &&
                            <Dropdown isOpen={notificationMenuOpen} toggle={toggleNotificationsMenuOpen} tag="li">
                                <DropdownToggle tag="i" className="ti-pin-alt">
                                    {false && <span></span>}
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu bell-notify-box notify-box">
                                    <span className="notify-title">Istoricul</span>
                                    <Scrollbars style={{ height: '350px' }}>
                                        <div className="nofity-list">

                                            {
                                                historyItems.map((event, index) => {
                                                    return (
                                                        <DropdownItem key={event.id} tag="a" htef="#" className="notify-item">
                                                            <div key={event.id} className={'history-event-third'} style={{ marginTop: 4, marginBottom: 4 }}>
                                                                <img className="rounded-circle event-image" src={event.info.userGender === 'male' ? maleImage : femaleImage} style={{ width: 50, height: 50, marginRight: 10 }} width="50" height="50" alt="TextALt" />
                                                                <div className="media-body event-body">
                                                                    <span style={{ color: '#363f5a', fontSize: 14, fontWeight: 500 }}>
                                                                        {event.info.userFullName}
                                                                        <br/>
                                                                        <small style={{ color: '#934A5F' }} className="font-weight-normal">
                                                                            {prettyDateAndTime(event.created)}
                                                                        </small>

                                                                    </span>

                                                                    <p style={{ color: '#000', paddingTop: 4, fontSize: 14, maxWidth: '100%', whiteSpace: 'pre-wrap' }} className="d-block">

                                                                        {parsePhraseSpecialFormatted(event.phrase).map((currentPhrase) => {

                                                                            if (currentPhrase.special) {
                                                                                return (
                                                                                    <span style={{ color: '#934A5F', fontWeight: 500, maxWidth: '100%' }}>
                                                                                        {currentPhrase.text}
                                                                                    </span>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    currentPhrase.text
                                                                                );
                                                                            }
                                                                        })}

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </DropdownItem>
                                                    );
                                                })
                                            }
                                        </div>

                                    </Scrollbars>
                                </DropdownMenu>
                            </Dropdown>
                        }

                        <li className="user-dropdown">
                            <Dropdown isOpen={userMenuOpen} toggle={toggleUserMenuOpen} tag="div" >
                                <DropdownToggle className="btn dropdown-toggle d-flex align-items-center" tag="div">
                                    <span className="d_none_sm">{user ? `${ user.firstName } ${ user.lastName }` : '...'}<i className="ti-angle-down"/></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu">

                                    <DropdownItem tag="a" href="#" onClick={() => {
                                        toUserPage(user.id);
                                    }}><SimpleIcon icon="ft-user"/> Profilul tau</DropdownItem>

                                    <DropdownItem tag="a" href="#" onClick={() => {
                                        history.push('/users');
                                    }}><SimpleIcon icon="ft-users"/> Utilizatori</DropdownItem>

                                    {
                                        false &&
                                        <DropdownItem tag="a" href="#" onClick={() => {
                                            setTimeout(() => {
                                                dispatch(applicationAddSnack({ label: 'Un email cu detaliile resetari a fost trimis la adresa ta.', action: kToastActionNormal }));
                                            }, 1000);
                                        }}><SimpleIcon icon="ft-lock"/> Reseteaza parola</DropdownItem>
                                    }

                                    <span role="separator" className="divider"/>

                                    <DropdownItem tag="a" href="#" onClick={() => {
                                        history.push('/download');
                                    }}><SimpleIcon icon="ft-download-cloud"/> Descarca</DropdownItem>

                                    <DropdownItem tag="a" href="#" onClick={() => {
                                        history.push('/system');
                                    }}><SimpleIcon icon="ft-sliders"/> Sistem</DropdownItem>

                                    <span role="separator" className="divider"/>

                                    <DropdownItem tag="a" href="#" onClick={onRefesh}><SimpleIcon icon="ft-refresh-cw"/> Reactualizeaza</DropdownItem>

                                    <DropdownItem tag="a" href="#" onClick={toggleFullscreen}><SimpleIcon icon="ft-maximize"/> Ecran complet</DropdownItem>

                                    <span role="separator" className="divider"/>

                                    <DropdownItem tag="a" href="#" onClick={() => {
                                        dispatch(applicationSetApplication(null));
                                    }}><SimpleIcon icon="ft-refresh-ccw"/> Schimba centrul</DropdownItem>

                                    <DropdownItem tag="a" className="text-danger" href="#" onClick={() => {
                                        logicLogout((successful) => {
                                            if (successful) {
                                                dispatch(applicationLogin(null));
                                                history.push('/logout');
                                            }
                                        });
                                    }}>
                                        <SimpleIcon icon="ft-power" color="#741508"/> Iesi din cont
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

/* Notifications

<Dropdown isOpen={notificationMenuOpen} toggle={toggleNotificationsMenuOpen} tag="li">
<DropdownToggle tag="i" className="ti-bell">
    <span></span>
</DropdownToggle>

<DropdownMenu className="dropdown-menu bell-notify-box notify-box">
    <span className="notify-title">Istoricul tau</span>
    <Scrollbars style={{ height: '350px' }}>
        <div className="nofity-list">

            {
                notifications.map((event, index) => {
                    return (
                        <DropdownItem key={event.id} tag="a" htef="#" className="notify-item">
                            <div className="notify-thumb"><i className="ti-panel" style={{ color: '#ffffff', backgroundColor: '#934A5F' }}></i></div>
                            <div className="notify-text">
                                <h4 style={{ fontSize: 14, fontWeight: 300 }}>
                                    {parsePhraseSpecialFormatted(event.phrase).map((currentPhrase) => {
                                        if (currentPhrase.special) {
                                            return (
                                                <span style={{ color: '#934A5F', fontSize: 14, fontWeight: 500 }}>
                                                    {currentPhrase.text}
                                                </span>
                                            );
                                        } else {
                                            return (
                                                currentPhrase.text
                                            );
                                        }
                                    })}
                                </h4>
                                <span>{`Acum ${ prettyDuration(moment.duration(moment().diff(event.created))) }`}</span>
                            </div>
                        </DropdownItem>
                    );
                })
            }
        </div>

    </Scrollbars>
</DropdownMenu>
</Dropdown>
*/

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
        changeCenter: () => { return dispatch(applicationSetApplication(null)); },
    };
};

export default Header;
