/**
 * @label Stable Imports
 */

import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/**
 * @label Unstable Imports
 */

import MetisMenu from 'metismenujs';

/**
 * @label Functionality Imports
 */

import {
    applicationDrawerOpenSelector,
    applicationSetApplication,
    applicationUserSelector,
} from '../store/application-store';
import sidebarRoutes from '../functional/sidebar-routes';
import { smallIcon } from '../functional/min-support';

/**
 *
 * @returns {JSX.Element}
 */

const Sidebar = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * @label Hooks : End
     */


    /**
     * @label Store : Start
     */

    const drawerOpen = useSelector(applicationDrawerOpenSelector);
    const user = useSelector(applicationUserSelector);

    /**
     * @label Store : End
     */


    /**
     * @label Functionality : Start
     */

    const update = (pathname) => {
        console.log('update called');
        let matchingMenuItem = null;
        const ul = document.getElementById('menu');
        if (ul) {
            const items = ul.getElementsByTagName('a');
            for (let i = 0; i < items.length; ++i) {
                disableParentDropdown(items[ i ]);
            }
            for (let i = 0; i < items.length; ++i) {
                if (pathname === items[ i ].pathname) {
                    matchingMenuItem = items[ i ];
                    break;
                }
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    };

    const activateParentDropdown = (item) => {
        item.classList.add('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('mm-active'); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show');
                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[ 0 ].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add('active');
                    }
                }
            }
            return false;
        }
    };

    const disableParentDropdown = (item) => {
        item.classList.remove('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.remove('mm-active'); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.remove('mm-show');
                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.remove('mm-active'); // li
                    parent3.childNodes[ 0 ].classList.remove('mm-active'); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.remove('active');
                    }
                }
            }
            return false;
        }
    };

    const mouseEnter = () => {
        return;
        if (!drawerOpen) {
            const pageClass = document.querySelector('.page-container');
            if (pageClass) {
                pageClass.classList.remove('sidebar_collapsed');
            }
        }
    };

    const mouseLeave = () => {
        return;
        if (!drawerOpen) {
            const pageClass = document.querySelector('.page-container');
            if (pageClass) {
                pageClass.classList.add('sidebar_collapsed');
            }
        }
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Effects : Start
     */

    useEffect(() => {
        new MetisMenu('#menu', {});
        update(history.location.pathname);
    }, []);

    history.listen((location, action) => {
        update(location.pathname);
    });

    /**
     * @label Effects : End
     */


    /**
     * @label Render
     */

    return (
        <React.Fragment>
            <div className="left side-menu">
                <div className="sidebar-menu light-sidebar" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    <div className="main-menu">
                        <div className="menu-inner" id="sidebar_menu">
                            <nav>
                                <ul className="metismenu" id="menu">

                                    {
                                        sidebarRoutes[ user.level ].map((sidebarRoute) => {
                                            const isParent = Boolean(sidebarRoute.children);
                                            return (
                                                <li key={sidebarRoute.id}>
                                                    <Link to={isParent ? '/#' : sidebarRoute.path}>
                                                        {
                                                            smallIcon(sidebarRoute.icon)
                                                        }
                                                        <div className={'active-line'}/>
                                                        <span> {sidebarRoute.title}</span>
                                                    </Link>
                                                    {
                                                        isParent &&
                                                        <ul className="submenu">
                                                            {
                                                                sidebarRoute.children.map((childRoute) => {
                                                                    return (
                                                                        <li key={childRoute.id}>
                                                                            <Link to={childRoute.path}>
                                                                                {
                                                                                    smallIcon(childRoute.icon)
                                                                                }
                                                                                <div className={'active-line'}/>
                                                                                <span> {childRoute.title}</span>
                                                                            </Link>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            );
                                        })
                                    }

                                </ul>
                            </nav>
                            

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

/*
                            <div className={'sidebar-button simple-button'} onClick={() => {
                                dispatch(applicationSetApplication(null));
                            }}>
                                {
                                    smallIcon('ft-refresh-ccw')
                                }
                                <span>{'Centrul'}</span>
                            </div>
*/

export default Sidebar;