import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getOperator, requestUserAuthentication } from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { filter, parsePhraseSpecialFormatted, prettyDateAndTime } from '../functional/min-support';
import { toTicketPage } from '../functional/navigation';
import { colorByStatus } from '../functional/status';
import { getLevelNameBasedOnLevel } from './UsersPage';
import maleImage from '../assets/images/global/user-placeholder-male.png';
import femaleImage from '../assets/images/global/user-placeholder-female.png';
import { applicationAddSnack, applicationOpenUpdateModal, applicationSetItemPageTitle } from '../store/application-store';
import { kToastActionError } from '../components/general/Toast';
import { saveCredentials } from '../functional/storage';

class UserPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,
    };

    state = UserPage.defaultState;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
        if (this.props.match.params.key !== nextProps.match.params.key) {
            this.generalUpdate();
        }
    }

    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }

    generalUpdate = () => {
        this.setState(UserPage.defaultState, this.update);
    };

    update = () => {
        const key = this.props.match.params.key;
        getOperator(key, (successful, data) => {
            console.log('operator', data);
            if (successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.fullName);
            }
        });
    };

    render () {
        // console.log('levels', this.props.user, this.state.item);
        const selfUserPage = this.props.user._id === this.props.match.params.key;
        //let operators = this.state.item ? translateUserOperators(this.state.item.operators ? this.state.item.operators : []) : null;
        const history = this.state.item ? this.state.item.history : null;
        //let tickets = this.state.item ? translateUserTickets(this.state.item.tickets) : null;
        let tickets = this.state.item ? this.state.item.created : null;
        //let assigned = this.state.item ? translateUserTickets(this.state.item.assigned) : null;
        let assigned = this.state.item ? this.state.item.assigned : null;
        const toolbarActions = [];
        const toolbarLabels = [
            {
                connection: 'solicitarile dupa:',
                tags: [ 'Titlul solicitarii', 'Statusul solicitarii' ],
            },
        ];

        if (tickets && this.state.search.length > 0) {
            tickets = filter(tickets, [ 'title', 'status' ], this.state.search, false);
        }

        if (assigned && this.state.search.length > 0) {
            assigned = filter(assigned, [ 'title', 'status' ], this.state.search, false);
        }

        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">

                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'} style={{ marginBottom: 20 }}>

                                    <TextFieldDisplay
                                        label={'numele de autentificare'}
                                        value={this.state.item.username}
                                        style={{ marginBottom: 4 }}
                                        icon={'ft-lock'}/>

                                    <TextFieldDisplay
                                        label={'prenume utilizator'}
                                        value={this.state.item.firstName}
                                        style={{ marginBottom: 4 }}
                                        icon={'ft-info'}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.firstName,
                                                'operator',
                                                'firstName'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'nume utilizator'}
                                        value={this.state.item.lastName}
                                        style={{ marginBottom: 4 }}
                                        icon={'ft-info'}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.lastName,
                                                'operator',
                                                'lastName'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        value={getLevelNameBasedOnLevel(this.state.item.level)}
                                        icon={'ft-layers'}
                                        style={{ marginBottom: 4 }}/>

                                </div>

                                <div className={'general-card'} style={{ marginBottom: 20 }}>

                                    <TextFieldDisplay
                                        label={'cod identificare fiscala'}
                                        value={this.state.item.cid}
                                        icon={'ft-info'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.cid,
                                                'user',
                                                'cid'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'nr. inregistrare la registrul comertului'}
                                        value={this.state.item.tri}
                                        icon={'blank'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.tri,
                                                'user',
                                                'tri'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'banca'}
                                        value={this.state.item.bankTitle}
                                        icon={'ft-credit-card'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.bankTitle,
                                                'user',
                                                'bankTitle'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'IBAN cont bancar'}
                                        value={this.state.item.bankAccount}
                                        icon={'blank'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item._id,
                                                this.state.item.bankAccount,
                                                'user',
                                                'bankAccount'
                                            );
                                        }}/>

                                </div>

                                <div className={'general-card'} style={{ marginBottom: 20 }}>

                                    {
                                        this.state.item._id === this.props.user._id &&
                                        <TextFieldDisplay
                                            label={'schimba parola'}
                                            value={'apasa pentru a schimba parola'}
                                            icon={'ft-info'}
                                            action={'ft-edit'}
                                            style={{ marginBottom: 4 }}
                                            onPress={() => {
                                                this.props.openUpdateModal(
                                                    this.state.item._id,
                                                    '',
                                                    'user',
                                                    'password'
                                                );
                                            }}/>
                                    }

                                    {
                                        this.props.user.levelNumber > this.state.item.level &&
                                        <TextFieldDisplay
                                            label={'cere credentiale'}
                                            value={'autentifica-te ca acest utilizator'}
                                            icon={'ft-info'}
                                            action={'ft-edit'}
                                            style={{ marginBottom: 4 }}
                                            onPress={() => {
                                                requestUserAuthentication(this.state.item._id, (authenticationSuccessful, data) => {
                                                    if (authenticationSuccessful) {
                                                        const token = data.token;
                                                        const username = this.state.item.username;
                                                        saveCredentials(username, token);
                                                        window.location.reload(false);
                                                    } else {
                                                        this.props.toast('Autentificare nu s-a putut realiza din cauza permisiunilor reduse.', kToastActionError);
                                                    }
                                                    console.log('authentication', authenticationSuccessful, data);
                                                });
                                            }}/>
                                    }

                                </div>

                                {
                                    this.state.item && tickets && assigned &&
                                    <React.Fragment>

                                        {
                                            assigned && assigned.length > 0 &&
                                            <h4>
                                                Solicitari atribuite momentan
                                            </h4>
                                        }

                                        {
                                            assigned.length > 0 && assigned.map((item) => {
                                                return (
                                                    <div key={item.id} className="general-card card-small-padding" style={{ marginBottom: 20 }}>

                                                        <div className="d-flex flex-row justify-content-between">
                                                            <div style={{ flex: 1, paddingRight: 20 }}>
                                                                <TextFieldDisplay
                                                                    style={{ marginBottom: 4 }}
                                                                    value={item.title}
                                                                    icon={'ft-check-square'}/>

                                                                <TextFieldDisplay
                                                                    textBackgroundColor={colorByStatus(item.status)}
                                                                    textColor={'#ffffff'}
                                                                    value={item.status}
                                                                    icon={'ft-code'}
                                                                    style={{ marginBottom: 4 }}/>

                                                                <TextFieldDisplay
                                                                    value={prettyDateAndTime(item.created)}
                                                                    icon={'ft-clock'}
                                                                    style={{ marginBottom: 4 }}/>

                                                                <TextFieldDisplay
                                                                    value={item.description}
                                                                    icon={'ft-file-text'}
                                                                    style={{ marginBottom: 4 }}/>

                                                                <TextFieldDisplay
                                                                    icon={'blank'}
                                                                    action={'ft-eye'}
                                                                    onPress={() => { toTicketPage(item._id); }}
                                                                    value={'Vezi solicitare'}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        {
                                            assigned && assigned.length <= 0 &&
                                            <h4>
                                                Nicio solicitare atribuita momentan
                                            </h4>
                                        }


                                        {
                                            tickets && tickets.length > 0 &&
                                            <h4>
                                                Solicitari create
                                            </h4>
                                        }

                                        {
                                            tickets.length > 0 && tickets.map((item) => {
                                                return (
                                                    <div key={item.id} className="general-card card-small-padding" style={{ marginBottom: 20 }}>

                                                        <div className="d-flex flex-row justify-content-between">
                                                            <div style={{ flex: 1, paddingRight: 20 }}>
                                                                <TextFieldDisplay
                                                                    style={{ marginBottom: 4 }}
                                                                    value={item.title}
                                                                    icon={'ft-check-square'}/>

                                                                <TextFieldDisplay
                                                                    textBackgroundColor={colorByStatus(item.status)}
                                                                    textColor={'#ffffff'}
                                                                    value={item.status}
                                                                    icon={'ft-code'}
                                                                    style={{ marginBottom: 4 }}/>

                                                                <TextFieldDisplay
                                                                    value={prettyDateAndTime(item.created)}
                                                                    icon={'ft-clock'}
                                                                    style={{ marginBottom: 4 }}/>

                                                                <TextFieldDisplay
                                                                    value={item.description}
                                                                    icon={'ft-file-text'}
                                                                    style={{ marginBottom: 4 }}/>

                                                                <TextFieldDisplay
                                                                    icon={'blank'}
                                                                    action={'ft-eye'}
                                                                    onPress={() => { toTicketPage(item._id); }}
                                                                    value={'Vezi solicitare'}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        {
                                            tickets && tickets.length <= 0 &&
                                            <h4>
                                                Nicio solicitare creata
                                            </h4>
                                        }
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>

                    <div className="h-100 overflow-scroll" style={{ width: '40%', padding: 20, paddingLeft: 0 }}>

                        {
                            this.state.item && history &&
                            <React.Fragment>

                                {
                                    history && history.length > 0 &&
                                    <h4>
                                        Evenimente si actiuni
                                    </h4>
                                }

                                {
                                    history.length > 0 && history.map((event) => {
                                        return (
                                            <div key={event.id} className={'history-event-third'}>
                                                <img className="rounded-circle event-image" src={event.info.userGender === 'male' ? maleImage : femaleImage} style={{ width: 50, height: 50, marginRight: 10 }} width="50" height="50" alt="TextALt" />
                                                <div className="media-body event-body">
                                                    <span style={{ color: '#363f5a', fontSize: 14, fontWeight: 500 }}>
                                                        {event.info.userFullName}
                                                        <br/>
                                                        <small style={{ color: '#934A5F' }} className="font-weight-normal">
                                                            {prettyDateAndTime(event.created)}
                                                        </small>

                                                    </span>

                                                    <span style={{ color: '#000', paddingTop: 4, fontSize: 14 }} className="d-block">

                                                        {parsePhraseSpecialFormatted(event.phrase).map((currentPhrase) => {

                                                            if (currentPhrase.special) {
                                                                return (
                                                                    <span style={{ color: '#934A5F', fontWeight: 500 }}>
                                                                        {currentPhrase.text}
                                                                    </span>
                                                                );
                                                            } else {
                                                                return (
                                                                    currentPhrase.text
                                                                );
                                                            }
                                                        })}

                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                                {
                                    history && history.length <= 0 &&
                                    <h4>
                                        Niciun eveniment sau actiune
                                    </h4>
                                }
                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },

        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserPage));
