import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import InternalToolbar from '../components/general/InternalToolbar';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import SimpleButton from '../components/input/SimpleButton';
import { applicationAddSnack } from '../store/application-store';
import { connect } from 'react-redux';
import { kToastActionError, kToastActionNormal } from '../components/general/Toast';
import { generateAllTickets } from '../functional/api';

class DownloadPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        search: '',
    };

    state = DownloadPage.defaultState;

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
        });
    };

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(DownloadPage.defaultState, this.update);
    };

    update = () => {

    };

    render () {
        return (
            <React.Fragment>

                <InternalToolbar
                    refresh
                    onRefesh={this.generalUpdate}
                    search
                    onSearchChange={this.searchChange}
                    searchValue={this.state.search}/>

                <div className={'scrollable-page ps-4 pb-4'}>

                    <Row className="w-100">
                        <Col className="col-8 stretched_card pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card_title">OlaCRM aplicatia instalabila</h4>
                                    <div className="d-flex mt-2 total_sales_sec mt-3">
                                        <div className="col-md-7 row no-gutters mt-2">
                                            <div className="col-sm-6">
                                                <div className="sales-info"><span className="sales-info-box bg-success mr-2"></span>
                                                    <div className="sales-info-body">
                                                        <h6>Windows</h6>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-win32-x64-10-05-24-v0-8-6.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 10 Mai 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-win32-x64-04-04-24-v0-8-5.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 04 Aprilie 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-win32-x64-29-02-24-v0-8-4.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 29 Februarie 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-win32-x64-27-02-24.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 27 Februarie 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 26 Ianuarie 2024</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="sales-info"><span className="sales-info-box bg-primary mr-2"></span>
                                                    <div className="sales-info-body">
                                                        <h6>MacOS</h6>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-darwin-x64-10-05-24-v0-8-6.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 10 Mai 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-darwin-x64-04-04-24-v0-8-5.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 04 Aprilie 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-darwin-x64-29-02-24-v0-8-4.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 29 Februarie 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); window.open('https://mincode.ro/olacrm-hosting/OlaCRM-darwin-x64-27-02-24.zip', '_blank', 'noreferrer'); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 27 Februarie 2024</small><br/>
                                                        <small><SimpleButton onPress={() => { this.props.toast('Descarcarea va incepe in cateva secunde.', kToastActionNormal); }} value={'Descarca'} /><span className="text-success ms-2"><i className="feather ft-calendar"></i></span> 26 Ianuarie 2024</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(DownloadPage));
