import React from 'react';
import LoginPage from '../pages/public/LoginPage';
import TicketsPage from '../pages/TicketsPage';
import SalesPage from '../pages/SalesPage';
import ClientsPage from '../pages/ClientsPage';
import UsersPage from '../pages/UsersPage';
import StockPage from '../pages/StockPage';
import DashboardPage from '../pages/DashboardPage';
import ReportsPage from '../pages/ReportsPage';
import TicketPage from '../pages/TicketPage';
import NotFoundProtectedPage from '../pages/NotFoundProtectedPage';
import LogoutPage from '../pages/public/LogoutPage';
import NotFoundPublicPage from '../pages/public/NotFoundPublicPage';
import StockItemPage from '../pages/StockItemPage';
import SalePage from '../pages/SalePage';
import ClientPage from '../pages/ClientPage';
import UserPage from '../pages/UserPage';
import MailPage from '../pages/MailPage';
import ClientGroupPage from '../pages/ClientGroupPage';
import CreateAccountAffiliatePage from '../pages/public/CreateAccountAffiliatePage';
import AffiliateDashboardPage from '../pages/AffiliateDashboardPage';
import DownloadPage from '../pages/DownloadPage';
import SystemPage from '../pages/SystemPage';

export const routeLogin = {
    id: 'login',
    path: '/login',
    title: 'Autentificare',
    component: LoginPage,
    public: true,
};

export const routeRegisterAffiliate = {
    id: 'register',
    path: '/register',
    title: 'Inregistrare',
    component: CreateAccountAffiliatePage,
    public: true,
};

export const routeLoginDefault = {
    id: 'login-default',
    path: '/',
    title: 'Autentificare',
    component: LoginPage,
    public: true,
};

export const routeLogout = {
    id: 'logout',
    path: '/logout',
    title: 'Deautentificare',
    component: LogoutPage,
    public: true,
};

export const routeNotFoundPublic = {
    id: 'not-found',
    path: '/not-found',
    title: 'Pagina inexistenta',
    icon: 'ft-help-circle',
    component: NotFoundPublicPage,
    public: true,
};


export const routeDashboard = {
    id: 'dashboard',
    path: '/dashboard',
    title: 'Solicitari',
    icon: 'ft-check-square',
    component: DashboardPage,
    // component: DashboardPage,
    public: false,
};

export const routeAffiliateDashboard = {
    id: 'affiliate-dashboard',
    path: '/dashboard',
    title: 'Acasa',
    icon: 'ft-home',
    component: AffiliateDashboardPage,
    public: false,
};

export const routeAffiliateAdminDashboard = {
    id: 'affiliate-admin-dashboard',
    path: '/dashboard',
    title: 'Acasa',
    icon: 'ft-home',
    component: AffiliateDashboardPage,
    public: false,
};

export const routeAffiliatePage = {
    id: 'affiliate',
    path: '/affiliate',
    title: 'Vanzari afiliati',
    icon: 'ft-credit-card',
    component: AffiliateDashboardPage,
    public: false,
};

export const routeTickets = {
    id: 'tickets',
    path: '/tickets',
    title: 'Solicitari',
    icon: 'ft-check-square',
    component: TicketsPage,
    public: false,
};

export const routeMail = {
    id: 'mail',
    path: '/mail',
    title: 'Emailuri',
    icon: 'ft-mail',
    component: MailPage,
    public: false,
};

export const routeSales = {
    id: 'sales',
    path: '/sales',
    title: 'Vanzari',
    icon: 'ft-file-text',
    component: SalesPage,
    public: false,
};

/*
 *export const routeInvoices = {
 *  id: 'invoices',
 *  path: '/invoices',
 *  title: 'Facturi',
 *  icon: 'ft-file-text',
 *  component: InvoicesPage,
 *  public: false,
 *};
 */

export const routeClients = {
    id: 'clients',
    path: '/clients',
    title: 'Clienti',
    icon: 'ft-briefcase',
    component: ClientsPage,
    public: false,
};

/*
 *export const routeMap = {
 *  id: 'map',
 *  path: '/map',
 *  title: 'Harta',
 *  icon: 'ft-map',
 *  component: MapPage,
 *  public: false,
 *};
 */

export const routeStock = {
    id: 'stock',
    path: '/stock',
    title: 'Produse',
    icon: 'ft-layers',
    component: StockPage,
    public: false,
};

/*
 *export const routeSuppliers = {
 *  id: 'suppliers',
 *  path: '/suppliers',
 *  title: 'Furnizori',
 *  icon: 'ft-box',
 *  component: SuppliersPage,
 *  public: false,
 *};
 */

export const routeUsers = {
    id: 'users',
    path: '/users',
    title: 'Utilizatori',
    icon: 'ft-users',
    component: UsersPage,
    public: false,
};

/*
 *export const routeNecessary = {
 *  id: 'necessary',
 *  path: '/necessary',
 *  title: 'Piese necesare',
 *  icon: 'ft-alert-triangle',
 *  component: NecessaryPage,
 *  public: false,
 *};
 */

/*
 *export const routeOrders = {
 *  id: 'orders',
 *  path: '/orders',
 *  title: 'Comenzi',
 *  icon: 'ft-shopping-bag',
 *  component: OrdersPage,
 *  public: false,
 *};
 */

export const routeReports = {
    id: 'reports',
    path: '/reports',
    title: 'Exemple rapoarte',
    icon: 'ft-trending-up',
    component: ReportsPage,
    public: false,
};

export const routeDownload = {
    id: 'download',
    path: '/download',
    title: 'Descarca',
    icon: 'ft-download-cloud',
    component: DownloadPage,
    public: false,
};

export const routeSystem = {
    id: 'system',
    path: '/system',
    title: 'Sistem',
    icon: 'ft-sliders',
    component: SystemPage,
    public: false,
};


export const routeTicket = {
    id: 'ticket',
    path: '/tickets/:key',
    title: 'Tichet',
    component: TicketPage,
    public: false,
};

export const routeSale = {
    id: 'sale',
    path: '/sales/:key',
    title: 'Aviz',
    component: SalePage,
    public: false,
};

/*
 *export const routeInvoice = {
 *  id: 'invoice',
 *  path: '/invoices/:key',
 *  title: 'Factura',
 *  component: InvoicePage,
 *  public: false,
 *};
 */

export const routeClient = {
    id: 'client',
    path: '/clients/:key',
    title: 'Client',
    component: ClientPage,
    public: false,
};

export const routeClientGroup = {
    id: 'client',
    path: '/client-group/:key',
    title: 'Grup de clienti',
    component: ClientGroupPage,
    public: false,
};

export const routeStockItem = {
    id: 'stock',
    path: '/stock/:key',
    title: 'Produs',
    component: StockItemPage,
    public: false,
};

/*
 *export const routeSupplier = {
 *  id: 'supplier',
 *  path: '/suppliers/:key',
 *  title: 'Furnizor',
 *  component: SupplierPage,
 *  public: false,
 *};
 */

/*
 *export const routeNecessaryItem = {
 *  id: 'necessary-item',
 *  path: '/necessary/:key',
 *  title: 'Piesa necesara',
 *  component: NecessaryItemPage,
 *  public: false,
 *};
 */

/*
 *export const routeOrder = {
 *  id: 'order',
 *  path: '/orders/:key',
 *  title: 'Comanda',
 *  component: OrderPage,
 *  public: false,
 *};
 */

export const routeUser = {
    id: 'user',
    path: '/users/:key',
    title: 'Utilizator',
    component: UserPage,
    public: false,
};


export const routeNotFoundProtected = {
    id: 'not-found',
    path: '/not-found',
    title: 'Pagina inexistenta',
    icon: 'ft-help-circle',
    component: NotFoundProtectedPage,
    public: false,
};

const routes = {};

routes.god = [
    routeDashboard,
    routeAffiliatePage,
    routeTickets,
    routeMail,
    routeSales,
    routeClients,
    routeStock,
    routeUsers,
    routeReports,
    routeNotFoundProtected,
    routeTicket,
    routeSale,
    routeClient,
    routeClientGroup,
    routeStockItem,
    routeUser,
    routeDownload,
    routeSystem,
];

routes.affiliate_admin = [
    routeDashboard,
    routeAffiliatePage,
    routeTickets,
    routeMail,
    routeSales,
    routeClients,
    routeStock,
    routeUsers,
    routeReports,
    routeNotFoundProtected,
    routeTicket,
    routeSale,
    routeClient,
    routeClientGroup,
    routeStockItem,
    routeUser,
    routeDownload,
];

routes.admin = [
    routeDashboard,
    routeAffiliatePage,
    routeTickets,
    routeMail,
    routeSales,
    routeClients,
    routeStock,
    routeUsers,
    routeReports,
    routeNotFoundProtected,
    routeTicket,
    routeSale,
    routeClient,
    routeClientGroup,
    routeStockItem,
    routeUser,
    routeDownload,
    routeSystem,
];

routes.operator = [
    routeDashboard,
    routeTickets,
    routeMail,
    routeSales,
    routeClients,
    routeStock,
    routeUsers,
    routeReports,
    routeNotFoundProtected,
    routeTicket,
    routeSale,
    routeClient,
    routeClientGroup,
    routeStockItem,
    routeUser,
    routeDownload,
    routeSystem,
];

routes.affiliate = [
    routeAffiliateDashboard,
    routeClient,
    routeClients,
    routeUser,
];

routes.outside = [
    routeLogin,
    routeRegisterAffiliate,
    routeLoginDefault,
    routeLogout,
    routeNotFoundPublic,
];

export default routes;
