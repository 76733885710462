import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { smallIcon } from '../../functional/min-support';

class SimpleIcon extends Component {

    static propTypes = {
        icon: PropTypes.string,
        color: PropTypes.string,
        size: PropTypes.number,
        style: PropTypes.object,
    };

    render () {
        let style = {};
        if (this.props.style) {
            style = { ...style, ...this.props.style };
        }
        return (
            smallIcon(this.props.icon, this.props.color ? this.props.color : '#363f5a', this.props.size ? this.props.size : 14, { ...style })
        );
    }

}

export default SimpleIcon;
