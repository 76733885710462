import { createSlice } from '@reduxjs/toolkit';
import { translateUser } from '../functional/logic';

/**
 * Manages application related information.
 */

const initialState = {
    user: null,

    applications: [],
    application: null,

    drawerOpen: false,

    itemPageTitle: 'Se incarca...',
    snack: [],
    snackMonitor: 0,

    modals: {
        updateModal: false,
        loadingModal: false,
        addEmailModal: false,

        pdfViewModal: false,
    },

    pdfViewModalInfo: {
        url: '',
        data: null,
    },

    updateModalInfo: {
        id: '',
        previousValue: '',
        type: '',
        on: '',
    },
};

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        applicationLogin: (state, action) => {
            state.user = translateUser(action.payload);
        },

        applicationSetApplications: (state, action) => {
            state.applications = action.payload;
        },
        applicationSetApplication: (state, action) => {
            state.application = action.payload;
        },

        applicationToggleDrawerOpen: (state) => {
            state.drawerOpen = !state.drawerOpen;
        },
        applicationSetItemPageTitle: (state, action) => {
            state.itemPageTitle = action.payload ? action.payload : 'Se incarca...';
        },

        applicationAddSnack: (state, action) => {
            const snack = [ ...state.snack ];
            snack.push({ label: action.payload.label, action: action.payload.action });
            state.snackMonitor += 1;
            state.snack = snack;
        },
        applicationRemoveSnack: (state) => {
            const snack = state.snack;
            snack.shift();
            state.snackMonitor += 1;
            state.snack = snack;
        },

        applicationOpenUpdateModal: (state, action) => {
            const modals = state.modals;
            const info = action.payload;
            modals.updateModal = true;
            const updateModal = {
                id: info.id,
                previousValue: info.previous,
                type: info.type,
                on: info.on,
            };

            state.modals = modals;
            state.updateModalInfo = updateModal;
            console.log('update modal info', updateModal);
        },
        applicationCloseUpdateModal: (state) => {
            console.log('close called');
            const modals = state.modals;
            modals.updateModal = false;
            const updateModal = {
                id: 0,
                previousValue: '',
                type: '',
                on: '',
            };

            state.modals = modals;
            state.updateModalInfo = updateModal;
        },

        applicationOpenPDFViewModal: (state, action) => {
            const modals = state.modals;
            const info = action.payload;
            modals.pdfViewModal = true;

            state.modals = modals;
            state.pdfViewModalInfo = { url: info.link, data: info.data };
        },
        applicationClosePDFViewModal: (state) => {
            const modals = state.modals;
            modals.pdfViewModal = false;

            state.modals = modals;
            state.pdfViewModalInfo = { url: '', data: null };
        },

        applicationOpenLoadingModal: (state) => {
            const modals = state.modals;
            modals.loadingModal = true;
            state.modals = modals;
        },
        applicationCloseLoadingModal: (state) => {
            const modals = state.modals;
            modals.loadingModal = false;
            state.modals = modals;
        },

        applicationOpenAddEmailModal: (state) => {
            const modals = state.modals;
            modals.addEmailModal = true;
            state.modals = modals;
        },
        applicationCloseAddEmailModal: (state) => {
            const modals = state.modals;
            modals.addEmailModal = false;
            state.modals = modals;
        },
    },
});

export const {
    applicationLogin,

    applicationSetApplication,
    applicationSetApplications,

    applicationToggleDrawerOpen,

    applicationSetItemPageTitle,

    applicationAddSnack,
    applicationRemoveSnack,

    applicationOpenUpdateModal,
    applicationCloseUpdateModal,

    applicationOpenPDFViewModal,
    applicationClosePDFViewModal,

    applicationOpenLoadingModal,
    applicationCloseLoadingModal,

    applicationOpenAddEmailModal,
    applicationCloseAddEmailModal,
} = applicationSlice.actions;

export const applicationIsAuthenticatedSelector = (state) => { return state.application.user !== null; };
export const applicationUserSelector = (state) => { return state.application.user; };

export const applicationApplicationsSelector = (state) => { return state.application.applications; };
export const applicationApplicationSelector = (state) => { return state.application.application; };

export const applicationDrawerOpenSelector = (state) => { return state.application.drawerOpen; };
export const applicationItemPageTitleSelector = (state) => { return state.application.itemPageTitle; };

export const applicationSnackSelector = (state) => { return state.application.snack; };
export const applicationSnackMonitorSelector = (state) => { return state.application.snackMonitor; };

export const applicationModalsSelector = (state) => { return state.application.modals; };

export const applicationUpdateModalOpenSelector = (state) => { return state.application.modals.updateModal; };
export const applicationUpdateModalInfoSelector = (state) => { return state.application.updateModalInfo; };

export const applicationPDFViewModalOpenSelector = (state) => { return state.application.modals.pdfViewModal; };
export const applicationPDFViewModalInfoSelector = (state) => { return state.application.pdfViewModalInfo; };

export const applicationLoadingModalOpenSelector = (state) => { return state.application.modals.loadingModal; };
export const applicationAddEmailModalOpenSelector = (state) => { return state.application.modals.loadingModal; };

export default applicationSlice.reducer;
