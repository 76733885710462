import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseModal from './model/BaseModal';
import ClientPicker from './pickers/ClientPicker';
import {
    inputValidationEmail,
    inputValidationNonEmpty,
    inputValidationSingleChoice,
    prettyDateAndTime,
} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    getAllMail,
    getApplicationEmailAddresses,
    getClient,
    getFiles, saveFile,
    saveMail,
    uploadResource,
} from '../../functional/api';
import { Editor } from '@tinymce/tinymce-react';
import { kToastActionError, kToastActionNormal } from '../general/Toast';
import {
    applicationAddSnack,
    applicationCloseLoadingModal,
    applicationOpenLoadingModal,
} from '../../store/application-store';
import MailInput from '../mail/MailInput';
import PickerModal from './model/PickerModal';
import AddDocumentModal from './AddDocumentModal';
import TextFieldDisplay from '../input/TextFieldDisplay';
import SimpleButton from '../input/SimpleButton';

const kSignatures = {
    'echipa-customer-management': 'Va multumim,<br/>Echipa Customer Management.',
};

class Modal extends Component {

    static propTypes = {
        user: PropTypes.object,

        clientId: PropTypes.number,

        open: PropTypes.bool,

        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,

        defaultData: PropTypes.object,
    };

    state = {

        subject: '',
        from: '',

        to: '',
        body: '',
        initialContent: '<p></p><br/><p>Va multumim,<br/>Echipa ...</p>',
        signatureId: null,
        signature: '',

        allAddresses: [],

        toSelected: [],
        ccSelected: [],
        bccSelected: [],

        formComplete: false,

        templates: [],
        loadTemplateModalOpen: false,

        files: [],
        clients: [],
        addFileModalOpen: false,

        clientForFileModalOpen: false,
        clientForFile: null,

        newDocumentOpen: false,

        addedDocuments: [],

        signatureUpdated: '',

        attachCheckboxActive: false,

    };


    constructor (props) {
        super(props);
        this.editor = React.createRef();
    }


    componentDidMount () {
        //let initialContent = "<p></p><br/><p>Va multumim,<br/>Echipa " + this.props.application.title + "</p>"
        // const initialContent = this.props.user.signature ? `<br/><br/>${ this.props.user.signature }` : '';
        const initialContent = '';
        this.setState({
            initialContent,
            signature: this.props.user.signature ? this.props.user.signature : '',
            signatureUpdated: this.props.user.signature ? this.props.user.signature : '',
            body: initialContent,
        });
        // setInterval(() => {
        //     this.props.toast('Ciorna a fost salvata cu succes', kToastActionNormal);
        // }, 5000);
    }

    addDocument = (title, url) => {
        const added = this.state.addedDocuments;
        added.push({ title, url });
        this.setState({
            addedDocuments: added,
        });
    }

    render () {
        const emailAddresses = [];
        if (this.props.application && this.props.application.email) {
            emailAddresses.push({
                value: this.props.application.email,
                label: this.props.application.email,
                description: 'Adresa de email prestabilita a centrului.',
            });
        }

        const insideStyle = {};
        const outsideStyle = {};
        if (!this.state.attachCheckboxActive) {
            insideStyle.backgroundColor = '#eeeeee';
        }

        return (
            <BaseModal
                title={'Trimite email'}
                stableHeight
                stableWidth
                ignoreKeyboard
                open={this.props.open}
                doneActive={this.state.formComplete}
                doneActionTitle={'Trimite'}
                overExpand

                onMoreButtons={[
                    {
                        label: 'Incarca sablon',
                        onPress: () => {
                            this.setState({ loadTemplateModalOpen: true });
                        },
                        icon: 'ft-download-cloud',
                    },
                    {
                        label: 'Salveaza sablon',
                        onPress: this.onSaveTemplate,
                        icon: 'ft-upload-cloud',
                    },
                    {
                        label: 'Document client',
                        onPress: () => {
                            this.setState({
                                // addFileModalOpen: true,
                                clientForFileModalOpen: true,
                            });
                        },
                        icon: 'ft-file',
                    },
                    {
                        label: 'Document general',
                        onPress: () => {
                            this.setState({
                                addFileModalOpen: true,
                                clientForFile: null,
                                // clientForFileModalOpen: true,
                            });
                        },
                        icon: 'ft-file',
                    },
                    {
                        label: 'Salveaza ciorna',
                        onPress: this.onSaveDraft,
                        icon: 'ft-save',
                    },
                ]}

                // onMore={() => {
                //     this.props.openLoading();
                //     setTimeout(() => {
                //         this.props.toast('Nu ai niciun document / sablon', kToastActionError);
                //         this.props.closeLoading();
                //     }, 2000);
                // }}
                // onMoreText={'Incarca document / sablon'}

                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        {/*<SingleChoiceField*/}
                        {/*    required*/}
                        {/*    validated={inputValidationSingleChoice(this.state.from)}*/}

                        {/*    icon={'ft-upload'}*/}
                        {/*    label={'Trimite de la adresa'}*/}
                        {/*    options={emailAddresses}*/}
                        {/*    value={this.state.from}*/}
                        {/*    style={{ marginBottom: 4 }}*/}
                        {/*    onSelect={(value) => {*/}
                        {/*        this.setState({*/}
                        {/*            from: value,*/}
                        {/*        });*/}
                        {/*    }}/>*/}

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflowX: 'scroll' }}>
                                <MailInput required validated={this.state.toSelected.length > 0} allAddresses={this.state.allAddresses} selected={this.state.toSelected} setSelected={(newSelected) => { this.setState({ toSelected: newSelected }); }} icon={'ft-at-sign'} title={'Catre'}/>
                                {
                                    (this.state.ccBccActivated || this.state.ccSelected.length > 0 || this.state.bccSelected.length > 0) &&
                                    <>
                                        <MailInput allAddresses={this.state.allAddresses} selected={this.state.ccSelected} setSelected={(newSelected) => { this.setState({ ccSelected: newSelected }); }} icon={'ft-chevron-right'} title={'CC'}/>
                                        <MailInput allAddresses={this.state.allAddresses} selected={this.state.bccSelected} setSelected={(newSelected) => { this.setState({ bccSelected: newSelected }); }} icon={'ft-chevrons-right'} title={'BCC'}/>
                                    </>
                                }
                            </div>
                            
                            {
                                !(this.state.ccBccActivated || this.state.ccSelected.length > 0 || this.state.bccSelected.length > 0) &&
                                    <SimpleButton value={'CC/BCC'} onPress={() => { this.setState({ ccBccActivated: true }); }}/>
                            }

                        </div>


                        {/*<SingleChoiceField*/}
                        {/*    required*/}
                        {/*    validated={inputValidationSingleChoice(this.state.clientId)}*/}

                        {/*    icon={'ft-briefcase'}*/}
                        {/*    label={'Catre clientul'}*/}
                        {/*    predefinedModal={'client'}*/}
                        {/*    value={this.state.clientId}*/}
                        {/*    initialValue={true}*/}
                        {/*    style={{ marginBottom: 4 }}*/}
                        {/*    onSelect={(value, allData) => {*/}
                        {/*        let toEmail = '';*/}
                        {/*        if (allData.email) {*/}
                        {/*            toEmail = allData.email;*/}
                        {/*        }*/}
                        {/*        this.setState({*/}
                        {/*            clientId: value,*/}
                        {/*            to: toEmail,*/}
                        {/*        });*/}
                        {/*    }}/>*/}

                        {/*{*/}
                        {/*    this.state.clientId &&*/}
                        {/*    <TextFieldInput*/}
                        {/*        required*/}
                        {/*        validated={inputValidationEmail(this.state.to)}*/}

                        {/*        icon={'ft-at-sign'}*/}
                        {/*        value={this.state.to}*/}
                        {/*        style={{ marginBottom: 4 }}*/}
                        {/*        label={'Pe email-ul'}*/}
                        {/*        onChange={(newValue) => {*/}
                        {/*            this.setState({*/}
                        {/*                to: newValue,*/}
                        {/*            });*/}
                        {/*        }}/>*/}
                        {/*}*/}

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.subject)}

                            icon={'ft-disc'}
                            value={this.state.subject}
                            style={{ marginBottom: 4 }}
                            label={'Subiect'}
                            onChange={(newValue) => {
                                this.setState({
                                    subject: newValue,
                                });
                            }}/>

                        <Editor
                            ref={this.editor}
                            initialValue={this.state.initialContent}
                            apiKey="wjd4drc8lojoep5pdpjwen59iz969savhoybeaf7v6imb7bp"
                            init={{
                                height: 480,
                                menubar: false,
                                placeholder: 'Corpul email-ului',
                                language: 'ro',
                                language_url: '/tinymce/ro.js',
                                editor_css: '/tinymce/style.css',
                                content_css: '/tinymce/style.css',
                                body_class: 'editor_class',
                                setup (ed) {
                                    ed.on('init', () => {
                                        ed.getContainer().style.borderRadius = '4px';
                                        ed.getContainer().style.border = 'none';
                                        ed.getContainer().style.boxShadow = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
                                        //ed.getContainer().className += ' with-border';
                                    });
                                },
                                content_style: "@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); body {font-family: Open Sans; font-size: 14px;}",
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount textcolor',
                                ],
                                toolbar: 'undo redo | formatselect bold italic forecolor backcolor | fontselect | alignleft aligncenter alignright alignjustify | link bullist numlist outdent indent',
                                font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Helvetica=helvetica; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Montserrat=montserrat,sans-serif;',
                            }}
                            onChange={this.handleEditorChange}
                        />

                        <div style={{ padding: 8, display: 'flex', flexDirection: 'row' }}>
                            <input style={{ accentColor: '#531f2e' }} type="checkbox" id="scales" name="scales"/>
                            <div style={{ padding: 4 }}>{'Include atasamentele precedente'}</div>
                        </div>

                        <div style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll' }}>
                            {
                                this.state.addedDocuments.length <= 0 &&
                                <div>Niciun document atasat</div>
                            }


                            {
                                this.state.addedDocuments.length > 0 &&
                                this.state.addedDocuments.map((document, index) => {
                                    return (
                                        <div key={index}>
                                            <TextFieldDisplay
                                                icon={'ft-download-cloud'}
                                                value={document.title}
                                                action={'view'}
                                                onPress={() => {
                                                    window.open(document.url, '_blank');
                                                }}
                                                style={{ marginBottom: 4, marginRight: 10, flex: 1 }}/>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        {
                            false &&
                            <Editor
                                ref={this.editor}
                                initialValue={this.state.signature}
                                apiKey="wjd4drc8lojoep5pdpjwen59iz969savhoybeaf7v6imb7bp"
                                init={{
                                    height: 480,
                                    menubar: false,
                                    placeholder: 'Semnatura',
                                    language: 'ro',
                                    language_url: '/tinymce/ro.js',
                                    editor_css: '/tinymce/style.css',
                                    content_css: '/tinymce/style.css',
                                    body_class: 'editor_class',
                                    setup (ed) {
                                        ed.on('init', () => {
                                            ed.getContainer().style.borderRadius = '4px';
                                            ed.getContainer().style.border = 'none';
                                            ed.getContainer().style.boxShadow = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
                                            //ed.getContainer().className += ' with-border';
                                        });
                                    },
                                    content_style: "@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); body {font-family: Open Sans; font-size: 14px;}",
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount textcolor',
                                    ],
                                    toolbar: 'undo redo | formatselect bold italic forecolor backcolor | fontselect | alignleft aligncenter alignright alignjustify | link bullist numlist outdent indent',
                                    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Helvetica=helvetica; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Montserrat=montserrat,sans-serif;',
                                }}
                                onChange={this.handleSignatureEditorChange}
                            />
                        }


                    </Form>

                </div>

                <PickerModal
                    title={'Alege sablon'} options={this.state.templates}
                    open={this.state.loadTemplateModalOpen}
                    initialSelectedValues={[]}
                    onClose={() => { this.setState({ loadTemplateModalOpen: false }); }}
                    onDone={(result) => {
                        console.log('picked', result);
                        if (result && result.length > 0) {
                            const foundTemplate = this.state.templates.find((item) => { return item._id === result[ 0 ]; });
                            if (foundTemplate) {
                                const loadedEmail = foundTemplate;
                                console.log('loaded email', loadedEmail);
                                this.setState({
                                    subject: loadedEmail.subject,
                                    // from: loadedEmail.from,

                                    // to: loadedEmail.to,
                                    body: loadedEmail.originalBody,
                                    initialContent: loadedEmail.originalBody,

                                    signature: this.props.user.signature ? this.props.user.signature : '',
                                    signatureUpdated: this.props.user.signature ? this.props.user.signature : '',

                                    toSelected: loadedEmail.to ? loadedEmail.to.map((entry) => { return entry.address; }) : [],
                                });
                            }
                        }
                        this.setState({ loadTemplateModalOpen: false });
                    }} search={true} maximumChoices={1} minimumChoices={1}/>

                <ClientPicker
                    open={this.state.clientForFileModalOpen}
                    value={null}
                    onClose={() => {
                        this.setState({ clientForFileModalOpen: false, clientForFile: null });
                    }}
                    onDone={(value, advanced) => {
                        this.setState({ clientForFileModalOpen: false, clientForFile: advanced, addFileModalOpen: true });
                        console.log('client picked', value, advanced);
                    }}/>

                <PickerModal
                    title={`Alege fisier${ this.state.clientForFile ? ` pentru clientul ${ this.state.clientForFile.label }` : '' }`}
                    options={this.state.clientForFile ? this.state.files.filter((file) => { return file.clientID === this.state.clientForFile.value; }) : this.state.files}
                    open={this.state.addFileModalOpen}
                    initialSelectedValues={[]}
                    onMore={() => { this.setState({ newDocumentOpen: true }); }}
                    onMoreText={'Document nou'}
                    onClose={() => { this.setState({ addFileModalOpen: false, clientForFile: null }); }}
                    onDone={(result) => {
                        console.log('picked file', result);
                        if (result && result.length > 0) {
                            const foundFile = this.state.files.find((f) => { return f.value === result[ 0 ]; });
                            console.log('foundFile', foundFile);
                            if (foundFile) {
                                // console.log('editor', this.editor);
                                // if (this.editor.current) {
                                //     this.editor.current.setContent(`<span>${ foundFile.title } : ${ foundFile.url }</span><br/>`);
                                // }


                                // this.setState({
                                //     subject: `${ foundFile.title } - ${ foundFile.url }`,
                                // });

                                this.addDocument(foundFile.title, foundFile.url);
                            }
                        }

                        this.setState({ addFileModalOpen: false, clientForFile: null });
                    }} search={true} searchInDescription={true} maximumChoices={1} minimumChoices={1}>

                    <AddDocumentModal
                        open={this.state.newDocumentOpen}
                        title={'Adauga document'}
                        onDone={(doc, docUrl, docTitle) => {
                            this.setState({ newDocumentOpen: false });
                            uploadResource(doc, (successful, data) => {
                                if (!successful) {
                                    return;
                                }
                                const url = data.versions[ 0 ].extended;
                                saveFile(docTitle, 'no-client', url, 'others', (fileSaved, info) => {
                                    console.log('file info', fileSaved, info);
                                    this.retrieveAllFiles();
                                    // this.update();
                                });
                            });
                        }}
                        onClose={() => { this.setState({ newDocumentOpen: false }); }} />

                </PickerModal>

            </BaseModal>
        );
    }

    translateFileType = (type) => {
        if (type === 'contracts') {
            return 'contract';
        } else if (type === 'offers') {
            return 'oferta';
        } else if (type === 'invoices-not-fiscal') {
            return 'factura proforma';
        } else if (type === 'invoices-fiscal') {
            return 'factura fiscala';
        }

        return 'alte documente';
    }

    handleEditorChange = (e) => {
        this.setState({
            body: e.target.getContent(),
        });
    };

    handleSignatureEditorChange = (e) => {
        this.setState({
            signatureUpdated: e.target.getContent(),
        });
    };

    onClose = (noSave = false) => {
        if (!noSave && (this.state.subject || this.state.from || this.state.toSelected || this.state.ccSelected || this.state.bccSelected)) {
            this.onSaveDraft();
        }
        this.clear();
        this.props.onClose();
    };

    onSaveDraft = () => {
        const to = [];
        to.push(...this.state.toSelected.map((item) => {
            return { address: item, type: 'to' };
        }));
        to.push(...this.state.ccSelected.map((item) => {
            return { address: item, type: 'cc' };
        }));
        to.push(...this.state.bccSelected.map((item) => {
            return { address: item, type: 'bcc' };
        }));
        const request = {
            subject: this.state.subject,
            from: this.state.from,
            to,
            folder: 'draft',
            body: this.state.body,
        };

        this.props.openLoading();

        saveMail(request, (successful, email) => {
            this.props.closeLoading();
            console.log('succ', successful);
            console.log('emal', email);
            if (successful) {
                this.props.toast('Ciorna a fost salvata cu succes!');
                this.clear();
                this.onClose();
            } else {
                this.props.toast('Ciorna nu a putut fi salvata!', kToastActionError);
            }
        });
    }

    onSaveTemplate = () => {
        const to = [];
        to.push(...this.state.toSelected.map((item) => {
            return { address: item, type: 'to' };
        }));
        to.push(...this.state.ccSelected.map((item) => {
            return { address: item, type: 'cc' };
        }));
        to.push(...this.state.bccSelected.map((item) => {
            return { address: item, type: 'bcc' };
        }));
        const request = {
            subject: this.state.subject,
            from: this.state.from,
            to,
            folder: 'template',
            body: this.state.body,
        };

        this.props.openLoading();

        saveMail(request, (successful, email) => {
            this.props.closeLoading();
            console.log('succ', successful);
            console.log('emal', email);
            if (successful) {
                this.props.toast('Sablonul a fost salvat cu succes!');
                this.clear();
                this.onClose();
            } else {
                this.props.toast('Sablonul nu a putut fi salvat!', kToastActionError);
            }
        });
    }

    onDone = () => {
        let body = this.state.body;
        const to = [];
        to.push(...this.state.toSelected.map((item) => { return { address: item, type: 'to' }; }));
        to.push(...this.state.ccSelected.map((item) => { return { address: item, type: 'cc' }; }));
        to.push(...this.state.bccSelected.map((item) => { return { address: item, type: 'bcc' }; }));

        if (this.state.addedDocuments.length > 0) {
            body += '<br/><br/>';
            this.state.addedDocuments.forEach((document) => {
                body += `${ document.title } - <a href="${ document.url }" target="_blank">Descarca</a><br/>`;
            });
        }

        // if (this.state.signatureUpdated) {
        //     body += `<br/><br/>${ this.state.signatureUpdated }`;
        // }

        const request = {
            subject: this.state.subject,
            from: this.state.from,
            to,
            folder: 'sent',
            body,
        };


        if (this.props.ticketId) {
            request.ticketId = this.props.ticketId;
        }

        if (this.props.clientId) {
            request.clientId = this.props.clientId;
        }

        this.props.openLoading();

        /*
         *setTimeout(() => {
         *  this.props.closeLoading();
         *  this.clear();
         *  this.onClose();
         *  this.props.toast('Email-ul a fost trimis cu succes!', kToastActionNormal);
         *}, 2000);
         */

        console.log('request', request);

        saveMail(request, (successful, email) => {
            this.props.closeLoading();
            console.log('succ', successful);
            console.log('emal', email);
            if (successful) {
                this.props.toast('Email-ul a fost trimis cu succes!');
                this.clear();
                this.onClose(true);
            } else {
                this.props.toast('Email-ul nu a putut fi trimis!', kToastActionError);
            }
        });

        /*
         *sendEmail(request, (successful, data) => {
         *  if(successful) {
         *      this.props.closeLoading();
         *      this.onClose();
         *  } else {
         *      this.clear();
         *      this.props.toast('Operatiune esuata!', kToastActionError);
         *      this.props.closeLoading();
         *      this.onClose();
         *  }
         *});
         */

    };

    componentWillReceiveProps (nextProps, nextContext) {
        if ((!this.props.open && nextProps.open) || (this.props.defaultData !== nextProps.defaultData)) {
            this.update(nextProps);
        }
    }

    retrieveAllEmailAddresses = () => {
        getApplicationEmailAddresses((successful, emailAddresses) => {
            if (successful) {
                console.log('addresses', emailAddresses);
                this.setState({
                    allAddresses: emailAddresses,
                });
            }
        });
    }

    retrieveAllTemplates = () => {
        getAllMail((successful, data) => {
            if (successful) {
                data = data.filter((item) => { return item.folder === 'template'; });
                data = data.map((item) => {
                    return {
                        ...item,
                        value: item._id,
                        label: item.subject,
                        description: item.body.replace(/<[^>]*>?/gm, ' ').replace(/&nbsp;/g, ''),
                        originalBody: item.body,
                        body: item.body.replace(/<[^>]*>?/gm, ' ').replace(/&nbsp;/g, ''),
                    };
                });
                console.log('templates', data);
                this.setState({
                    templates: data,
                });
            }
        });
    }

    retrieveAllFiles = () => {
        getFiles((successful, data) => {
            if (successful) {
                console.log('files', data);
                // data = data.filter((item) => { return item.folder === 'template'; });
                data = data.map((item) => {
                    return {
                        ...item,
                        value: item._id,
                        label: `${ item.title } (${ this.translateFileType(item.path) })`,
                        description: `Client ${ item.clientID }`,
                    };
                });
                data = data.sort((a, b) => { return a.title.localeCompare(b.title); });
                // console.log('templates', data);
                this.setState({
                    files: data,
                });
            } else {
                console.log('failed', data);
            }
        });
    }

    update = (props) => {
        this.retrieveAllEmailAddresses();
        this.retrieveAllTemplates();
        this.retrieveAllFiles();

        if (props.loadedEmail) {
            const loadedEmail = props.loadedEmail;
            console.log('loaded email', loadedEmail);
            this.setState({
                subject: loadedEmail.subject,
                from: loadedEmail.from,

                to: loadedEmail.to,
                body: loadedEmail.originalBody,
                initialContent: loadedEmail.originalBody,

                signature: this.props.user.signature ? this.props.user.signature : '',
                signatureUpdated: this.props.user.signature ? this.props.user.signature : '',

                toSelected: loadedEmail.to ? loadedEmail.to.map((entry) => { return entry.address; }) : [],
            });
            return;
        }

        let initialContent = '<p></p>';

        if (props.user.signature) {
            initialContent += `<br/>${ props.user.signature }`;
        }

        if (props.initialReplyEmail) {
            initialContent += `<br/><h4>Pe data ${ prettyDateAndTime(props.initialReplyEmail.created) }, ${ props.initialReplyEmail.from } a scris:</h4><blockquote>${ props.initialReplyEmail.body }</blockquote>`;
            console.log('initial email', props.initialReplyEmail);
            const initialTo = [];
            if (props.initialReplyEmail.from) initialTo.push(props.initialReplyEmail.from);
            // if (props.initialReplyEmail.to) initialTo.push(props.initialReplyEmail.to.map((item) => { return item.address; }));
            if (props.initialReplyEmail.subject) this.setState({ subject: `RE: ${ props.initialReplyEmail.subject }` });
            this.setState({ toSelected: initialTo });
        }

        if (props.initialReplyAllEmail) {
            initialContent += `<br/><h4>Pe data ${ prettyDateAndTime(props.initialReplyAllEmail.created) }, ${ props.initialReplyAllEmail.from } a scris:</h4><blockquote>${ props.initialReplyAllEmail.body }</blockquote>`;
            console.log('initial email', props.initialReplyAllEmail);
            const initialTo = [];
            if (props.initialReplyAllEmail.from) initialTo.push(props.initialReplyAllEmail.from);
            if (props.initialReplyAllEmail.to) {
                const remaining = props.initialReplyAllEmail.to.filter((item) => { return item.address !== 'crm@utildeco.ro'; });
                initialTo.push(props.initialReplyAllEmail.to.map((item) => { return item.address; }));
            }
            if (props.initialReplyAllEmail.subject) this.setState({ subject: `RE: ${ props.initialReplyAllEmail.subject }` });
            this.setState({ toSelected: initialTo });
        }

        if (props.initialForwardEmail) {
            initialContent += `<br/><h4>Pe data ${ prettyDateAndTime(props.initialForwardEmail.created) }, ${ props.initialForwardEmail.from } a scris:</h4><blockquote>${ props.initialForwardEmail.body }</blockquote>`;
            console.log('initial email', props.initialForwardEmail);
            const initialTo = [];
            // if (props.initialForwardEmail.from) initialTo.push(props.initialForwardEmail.from);
            // if (props.initialForwardEmail.to) initialTo.push(props.initialForwardEmail.to.map((item) => { return item.address; }));
            if (props.initialForwardEmail.subject) this.setState({ subject: `FW: ${ props.initialForwardEmail.subject }` });
            this.setState({ toSelected: initialTo });
        }

        this.setState({
            initialContent,
            body: initialContent,

            signature: this.props.user.signature ? this.props.user.signature : '',
            signatureUpdated: this.props.user.signature ? this.props.user.signature : '',
        });

        if (props.defaultData) {
            if (props.defaultData.clientId) {
                this.setState({
                    clientId: props.defaultData.clientId,
                }, () => {
                    getClient(props.defaultData.clientId, (successful, client) => {
                        if (successful && client && client.email && client.id === this.state.clientId) {
                            this.setState({
                                to: client.email,
                            });
                        }
                    });
                });
            }

            if (props.defaultData.subject) {
                this.setState({
                    subject: props.defaultData.subject,
                });
            }

            if (props.defaultData.body) {
                this.setState({
                    body: props.defaultData.body,
                });
            }

            if (props.defaultData.signature) {
                this.setState({
                    signatureId: props.defaultData.signature,
                    signature: kSignatures[ props.defaultData.signature ],
                });
            }
        }
        setTimeout(() => {
            if (!this.state.from && this.props.application.email) {
                this.setState({ from: this.props.application.email });
            }
        }, 200);
    };

    clear = () => {
        this.setState({
            subject: '',
            from: '',
            clientId: null,
            to: '',
            body: '',
            signatureId: '',
            signature: '',
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        application: state.application.application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },

        openLoading: () => { return dispatch(applicationOpenLoadingModal()); },
        closeLoading: () => { return dispatch(applicationCloseLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
