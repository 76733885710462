import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InternalToolbar from '../../components/general/InternalToolbar';
import { filter, smallIcon } from '../../functional/min-support';
import PickerModal from '../../components/modal/model/PickerModal';
import { TableListRow } from './TablePage';
import TextFieldDisplay from '../../components/input/TextFieldDisplay';

class PagedPage extends Component {
    static propTypes = {
        onRefresh: PropTypes.func,
        loading: PropTypes.bool,
        deepSearch: PropTypes.bool,

        actionText: PropTypes.string,
        onAction: PropTypes.func,

        allListItems: PropTypes.array,

        allFields: PropTypes.array,
        defaultFields: PropTypes.array,

        fragments: PropTypes.array,

        children: PropTypes.array,

        customRender: PropTypes.bool,
    };

    state = {
        search: '',
        fields: [],
        filterModal: false,

        activeFragment: null,

        maxElement: 100,
    };

    lastSize = 0;

    matchFields = (fields) => {
        const matchedFields = [];
        for (let i = 0; i < this.props.allFields.length; ++i) {
            if (fields.includes(this.props.allFields[ i ].value)) {
                matchedFields.push(this.props.allFields[ i ]);
            }
        }
        return matchedFields.map((item) => { return item.label; });
    };

    componentDidMount () {
        this.setState({
            search: '',
            fields: this.props.defaultFields,
        });

        if (this.props.defaultFragment) {
            this.setState({
                activeFragment: this.props.defaultFragment,
            });
        }

        this.lastSize = 0;
    }

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
        });
        this.lastSize = 0;
    };

    render () {

        const fragment = this.state.activeFragment;
        const overallStyle = {};
        const style = {};

        const { tableFields, customContainer } = this.props;
        const tableFieldsKeys = Object.keys(tableFields);
        let nbOfCols = 0;
        let pointWeight = 0;
        tableFieldsKeys.forEach((fieldKey) => {
            const field = tableFields[ fieldKey ];
            pointWeight += field.weight ? field.weight : 1;
            nbOfCols += 1;
        });
        pointWeight = 100 / pointWeight;

        let list = [];
        if (!this.props.loading) {
            let elements = this.props.allListItems;
            if (this.state.sort && tableFields[ this.state.sort ] && tableFields[ this.state.sort ].compare) {
                elements = elements.sort(tableFields[ this.state.sort ].compare);
                if (this.state.sortReverse) {
                    elements = elements.reverse();
                }
            }
            if (fragment && fragment.type) {
                if (fragment.type === 'list') {
                    elements = elements.filter(fragment.listFilter);
                }
            }
            elements = filter(elements, this.state.fields, this.state.search, this.props.deepSearch).slice(0, this.state.maxElement);

            if (!this.props.customRender) {
                list = elements.map((item, itemIndex) => {
                    return <TableListRow key={itemIndex} item={item} tableFields={tableFields}
                        tableFieldsKeys={tableFieldsKeys} customContainer={customContainer}
                        nbOfCols={nbOfCols}/>;
                });
            } else if (this.props.renderListItem) {
                list = elements.map((item, itemIndex) => {
                    return this.props.renderListItem(item);
                });
            }
        }

        if (this.props.paddingOverall) {
            style.padding = 20;
        }

        /*
         * let elements = [];
         * if (!this.props.loading) {
         *     elements = filter(this.props.allListItems, this.state.fields, this.state.search, this.props.deepSearch);
         * }
         */

        const toolbarActions = [
            {
                title: 'Filtre',
                onPress: this.openFilterModal,
                icon: { title: 'ft-filter' },
            },
        ];
        if (this.props.actions) {
            toolbarActions.push(...this.props.actions);
        }

        const toolbarLabels = [
            {
                connection: 'dupa:',
                tags: this.matchFields(this.state.fields),
            },
        ];

        if (this.props.labels) {
            toolbarLabels.push(...this.props.labels);
        }

        let fragmentContent = null;
        if (fragment && fragment.type) {
            if (fragment.type === 'list') {

                fragmentContent =
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

                    <div className={'list-table-container'} style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', paddingLeft: 0, paddingTop: 10, paddingBottom: 10, paddingRight: 0, overflowY: 'scroll' }}>

                        {
                            !this.props.customRender &&
                            <table className={'list-table'}>

                                <colgroup>
                                    {
                                        tableFieldsKeys.map((fieldKey) => {
                                            const field = tableFields[ fieldKey ];
                                            return (
                                                <col key={fieldKey} style={{ width: `${ pointWeight * (field.weight ? field.weight : 1) }%` }}/>
                                            );
                                        })
                                    }
                                </colgroup>


                                <thead style={{ position: 'sticky', top: 0, zIndex: 20 }}>

                                    <tr className={'table-list-item-row table-list-item-row-header'}>
                                        {
                                            tableFieldsKeys.map((fieldKey) => {
                                                const field = tableFields[ fieldKey ];
                                                return (
                                                    <th
                                                        key={fieldKey}
                                                        onClick={() => {
                                                            if (field.compare) {
                                                                this.setState({
                                                                    sort: fieldKey,
                                                                    sortReverse: this.state.sort === fieldKey && !this.state.sortReverse,
                                                                });
                                                            }
                                                        }}
                                                        className={`table-list-item-cell-header ${ field.compare ? 'table-list-item-cell-header-action' : '' }`}>
                                                        {field.icon &&
                                                    <span style={{ marginRight: 4 }}>{smallIcon(field.icon)}</span>}
                                                        {field.title}
                                                        {this.state.sort === fieldKey && !this.state.sortReverse &&
                                                    <span style={{ marginLeft: 4 }}>{smallIcon('ft-chevron-down')}</span>}
                                                        {this.state.sort === fieldKey && this.state.sortReverse &&
                                                    <span style={{ marginLeft: 4 }}>{smallIcon('ft-chevron-up')}</span>}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>


                                </thead>

                                <tbody>

                                    {
                                        list
                                    }

                                    {
                                        this.props.allListItems && this.props.allListItems.length >= this.state.maxElement &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                        <TextFieldDisplay
                                            icon={'blank'}
                                            action={'ft-plus'}
                                            onPress={() => { this.setState({ maxElement: this.state.maxElement + 100 }); }}
                                            value={'Mai incarca'}/>
                                    </div>
                                    }


                                </tbody>

                            </table>
                        }

                        {
                            this.props.customRender &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {
                                        list
                                    }

                                    {
                                        this.props.allListItems && this.props.allListItems.length >= this.state.maxElement &&
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                            <TextFieldDisplay
                                                icon={'blank'}
                                                action={'ft-plus'}
                                                onPress={() => { this.setState({ maxElement: this.state.maxElement + 100 }); }}
                                                value={'Mai incarca'}/>
                                        </div>
                                    }
                                </div>
                        }

                    </div>

                    {/*<div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', paddingLeft: 10, paddingTop: 10, paddingBottom: 10, paddingRight: this.props.paddingRight ? 10 : 0, overflowY: 'scroll' }}>*/}
                    {/*    {elements.map(this.props.renderListItem)}*/}
                    {/*</div>*/}

                    {
                        this.props.renderDetailedView &&
                        <div style={{ display: 'flex', flexDirection: 'column', width: this.props.detailedViewOpen ? 600 : 0, overflow: 'scroll', padding: 10 }}>
                            {this.props.renderDetailedView()}
                        </div>
                    }

                </div>;

                //fragmentContent = elements.map(this.props.renderListItem);
            }
        }

        return (
            <React.Fragment>

                <div className={'paged-page'} style={overallStyle}>

                    <InternalToolbar
                        refresh
                        onRefresh={this.props.onRefresh}
                        search
                        onSearchChange={this.searchChange}
                        searchValue={this.state.search}
                        actions={toolbarActions}
                        labels={toolbarLabels}/>

                    {
                        this.props.loading &&
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    }

                    {
                        !this.props.loading &&
                        <div className={'paged-main-container'}>
                            <div className={'paged-fragment-list'}>
                                {this.props.fragments && this.props.fragments.map((fragment) => {
                                    return (
                                        <FragmentListItem
                                            item={fragment}
                                            level={0}
                                            onPress={(clicked) => {
                                                this.setState({
                                                    activeFragment: clicked,
                                                });
                                            }}
                                            activeId={this.state.activeFragment ? this.state.activeFragment.id : 'no-fragment'}/>
                                    );
                                })}
                            </div>
                            <div className={'paged-fragment-container'}>
                                {fragmentContent}
                            </div>
                        </div>
                    }

                </div>

                <PickerModal
                    title={'Alege campurile cautarii'}
                    open={this.state.filterModal}
                    options={this.props.allFields}
                    initialSelectedValues={this.state.fields}
                    minimumChoices={1}
                    maximumChoices={100}
                    onClose={this.closeFilterModal}
                    onDone={this.doneFilterModal}
                    returnMatchedFields/>

                {
                    this.props.children
                }

            </React.Fragment>
        );
    }

    openFilterModal = () => {
        this.setState({
            filterModal: true,
        });
    };

    closeFilterModal = () => {
        this.setState({
            filterModal: false,
        });
    };

    doneFilterModal = (newFilters, matchedFields) => {
        this.setState({
            fields: newFilters,
        });
        this.closeFilterModal();
    };
}

const FragmentListItem = (props) => {
    const { item, level, activeId, onPress } = props;

    const style = {};
    if (activeId === item.id) {
        style.backgroundColor = '#3a3247';
        style.color = '#fff';
        style.fontWeight = 600;

        if (item.color) {
            style.color = '#fff';
            style.backgroundColor = item.color;
        }
    }

    if (item.children) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 12, width: '100%' }}>
                <FragmentListItem item={{ ...item, children: null }} activeId={activeId} level={level} onPress={() => {}} parent/>
                {
                    item.children.map((child) => {
                        return (
                            <FragmentListItem item={child} activeId={activeId} level={level + 1} onPress={onPress}/>
                        );
                    })
                }
            </div>
        );
    }

    if (props.parent) {
        style.fontWeight = 600;
        style.cursor = 'inherit';
    }

    style.paddingLeft = (level - 1) * 20 + 12;
    if (item.noDisplay) {
        return null;
    }
    return (
        <div className={'fragment-list-item'} style={style} onClick={() => { onPress(item); }}>
            {item.icon ?
                smallIcon(item.icon, null, null, { paddingRight: 8 }) :
                null}
            {item.title}
            {item.label ?
                <div className={'fragment-list-item-label'}>{item.label}</div> :
                null}
        </div>
    );
};

export default PagedPage;
